<template>
  <v-app >
    <v-app-bar
      app
      dense
      elevation="0"
      color="transparent"
      clipped-right
      dark
    >
      <div class="d-flex align-center" >
        <img width="42" height="42" src="/logo.png">
      </div>
      <v-toolbar-title style="width: 200px">Doge Finance</v-toolbar-title>


      <v-tabs

      active-class="tabs-active"
      hide-slider
      >
        <v-tab>Trade</v-tab>
            <v-tab>Earn</v-tab>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
                v-bind="attrs"
                v-on="on"
                style="height: 100%;display: flex">
              <v-tab
                  disabled
                  v-tooltip="{ disabled: false, content: 'Tooltip content' }">Build</v-tab>
            </div>
          </template>
          <span>Coming soon</span>
        </v-tooltip>
      </v-tabs>
      <v-spacer></v-spacer>

      <div style="display: flex;align-items: center;gap: 8px">
        <img style="width: 1.1em;height: 1.1em" src="https://doggy.market/assets/doge-C39RGl_C.svg" class="doge-value">

        <div style="font-size: 16px; font-weight: bold">

          82.30
        </div>

      </div>
      <connect-wallet :wallet="this.wallet" :openDrawer="openDrawer" :connectWallet="connectWallet"/>
      <img width="24" src="https://testnet.laikachain.dog/assets/network_logo_dark.png"/>
    </v-app-bar>


    <v-main dark style="background: black">
      <div        style="background-color:#1E1E1E;padding-left: 64px">
      <v-tabs
          background-color="#1E1E1E"
          active-class="tabs-active"
          slider-color="#41B883"
    dark

          height="40px"
      >
        <v-tab>Swap</v-tab>
        <v-tab>Limit order</v-tab>
        <v-tab disabled>Marketplace</v-tab>
      </v-tabs>
      </div>
      <div>
        <div style="align-items:center;background:#121826;display: flex;width: 100%;height: 38px;border-bottom: 1px solid #484c53;border-top: 1px solid #484c53">
          <v-item-group mandatory style="display: flex;align-items: center;padding-left: 16px;gap: 2px">
            <v-item v-slot="{ active, toggle }">
              <div
                  :style="active ? 'color: #2962ff' : ''"
                  class="d-flex align-center chartSelector"
                  dark
                  height="200"
                  @click="handleClick(toggle,'1h',null)"
              >
                1h
              </div>
            </v-item>
            <v-item v-slot="{ active, toggle }">
                        <div
                            :style="active ? 'color: #2962ff' : ''"
                            class="d-flex align-center chartSelector"
                            dark
                            height="200"
                            @click="handleClick(toggle,'4h',null)"
                        >
                          4h
                        </div>
                  </v-item>
            <v-item v-slot="{ active, toggle }">
              <div
                  class="d-flex align-center chartSelector"
                  :style="active ? 'color: #2962ff' : ''"
                  dark
                  height="200"
                  @click="handleClick(toggle,'1d',null)"
              >
                D
              </div>
            </v-item>
          </v-item-group>
          <div style="height: 20px;padding-left:8px;margin-right:8px;border-right: 1px solid #484c53"></div>
          <v-item-group mandatory style="display: flex;align-items: center;gap: 2px">
            <v-item v-slot="{ active, toggle }">
              <div
                  :style="active ? 'color: #2962ff' : ''"
                  class="d-flex align-center chartSelector"
                  dark
                  height="200"
                  @click="handleClick(toggle,'1h',null)"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" d="m25.39 7.31-8.83 10.92-6.02-5.47-7.16 8.56-.76-.64 7.82-9.36 6 5.45L24.61 6.7l.78.62Z"></path></svg>
              </div>
            </v-item>
            <v-item v-slot="{ active, toggle }">
              <div
                  :style="active ? 'color: #2962ff' : ''"
                  class="d-flex align-center chartSelector"
                  dark
                  height="200"
                  @click="handleClick(toggle,'4h',null)"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" fill="currentColor"><path d="M17 11v6h3v-6h-3zm-.5-1h4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5z"></path><path d="M18 7h1v3.5h-1zm0 10.5h1V21h-1z"></path><path d="M9 8v12h3V8H9zm-.5-1h4a.5.5 0 0 1 .5.5v13a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 .5-.5z"></path><path d="M10 4h1v3.5h-1zm0 16.5h1V24h-1z"></path></svg>
              </div>
            </v-item>
          </v-item-group>
          <div style="height: 20px;padding-left:8px;margin-right:8px;border-right: 1px solid #484c53"></div>

          <v-item-group mandatory style="display: flex;align-items: center;gap: 16px">
            <div style="display: flex;align-items: center;gap: 6px">
            <v-item v-slot="{ active, toggle }">
              <div
                  :style="active ? 'color: #2962ff' : ''"
                  class="d-flex align-center chartSelector"
                  dark

                  @click="handleClick(toggle,null,'USD')"
              >
                USD
              </div>
            </v-item>
            <div style="color: #d1d4dc;font-weight: 600"> /</div>

            </div>
            <div>
            <v-item v-slot="{ active, toggle }">
              <div
                  :style="active ? 'color: #2962ff' : ''"
                  class="d-flex align-center chartSelector"
                  dark
                  height="200"
                  @click="handleClick(toggle,null,'DOGE')"
              >
                DOGE
              </div>
            </v-item>
            </div>
          </v-item-group>

        </div>

    <TradingVue
        :toolbar="true"
        titleTxt="DOGE/LAIKA on Wufiswap"
        :width="this.width"
        :height="this.height"
        :data="this.data"
        :extensions="ext"
        ref="tradingVue"/>
      </div>
      <div style="position: relative;background: #424242;height: 4px">

      <div class="slider-wrapper"
           @mousedown="handleSliderMouseDown"
           @touchstart="handleSliderMouseDown"
           ref="sliderWrapper"
      >
        <v-icon small color="rgb(66, 66, 66)" style="position:absolute;left: 50%;top: -6px;">mdi-arrow-up</v-icon>
        <v-icon small color="rgb(66, 66, 66)" style="position:absolute;left: 50%;top: 9px;">mdi-arrow-down</v-icon>

        <div class="slider"></div>
      </div>
      </div>
      <v-tabs
       background-color="black"
       height="40"
          slider-color="#41B883"
          dark
       v-model="tab2"
      >
        <v-tab href="#tab-1-2">Transactions</v-tab>
        <v-tab href="#tab-2-2">Holders (254)</v-tab>
        <v-tab href="#tab-3-2">Liquidity providers (1)</v-tab>
      </v-tabs>
      <v-tabs-items
          v-model="tab2"
          dark
      >
        <v-tab-item value="tab-1-2" dark eager transition="none">
      <v-data-table

          :height="tableHeight"
          :headers="headers"
          fixed-header
          hide-default-footer
          pagination="false"
          :items="txns"
          dark
          dense
          style="    scrollbar-width: auto;
    scrollbar-color: rgb(77, 77, 77) rgb(40, 40, 45);"
          class="elevation-1"


      >
        <template v-slot:[`item.relativeTime`]="{ item }">
      <span style="color: #848489;font-size: 12px">
          {{ item.relativeTime }}
      </span>
        </template>
        <template v-slot:[`item.type`]="{ item }">
      <span :style="{ color: item.type === 'Buy' ? '#48BB78' : (item.type === 'Sell' ? '#F56565' : 'white'), fontWeight: item.type === 'Buy' || item.type === 'Sell' ? 'bold' : 'normal' }">
        {{ item.type }}
      </span>
        </template>
        <template v-slot:[`item.usd`]="{ item }">
      <span :style="{ color: item.type === 'Buy' ? '#48BB78' : (item.type === 'Sell' ? '#F56565' : 'white'), fontWeight: item.type === 'Buy' || item.type === 'Sell' ? 'bold' : 'normal' }">
        {{ parseFloat(item.usd).toLocaleString() }}
      </span>
        </template>
        <template v-slot:[`item.token`]="{ item }">
      <span :style="{ color: item.type === 'Buy' ? '#48BB78' : (item.type === 'Sell' ? '#F56565' : 'white'), fontWeight: item.type === 'Buy' || item.type === 'Sell' ? 'bold' : 'normal' }">
        {{ parseFloat(item.token).toLocaleString() }}
      </span>
        </template>
        <template v-slot:[`item.doge`]="{ item }">
      <span :style="{ color: item.type === 'Buy' ? '#48BB78' : (item.type === 'Sell' ? '#F56565' : 'white'), fontWeight: item.type === 'Buy' || item.type === 'Sell' ? 'bold' : 'normal' }">
        {{ parseFloat(item.doge).toLocaleString() }}
      </span>
        </template>
        <template v-slot:[`item.price`]="{ item }">
      <span :style="{ color: item.type === 'Buy' ? '#48BB78' : (item.type === 'Sell' ? '#F56565' : 'white'), fontWeight: item.type === 'Buy' || item.type === 'Sell' ? 'bold' : 'normal' }">
        {{ parseFloat(item.price).toLocaleString()  }}
      </span>

        </template>
        <template v-slot:[`item.txid`]="{ item }">
      <span >

              <v-icon :id="item.txid" color="#848489" small>mdi-open-in-new</v-icon>

      </span>
        </template>

      </v-data-table>
        </v-tab-item>
        <v-tab-item value="tab-2-2" transition="none" dark eager>
          <v-data-table
              :height="tableHeight"
              :headers="holdersHeaders"
              fixed-header
              hide-default-footer
              pagination="false"
              :items="holders"
              dark
              dense
              style="    scrollbar-width: auto;
    scrollbar-color: rgb(77, 77, 77) rgb(40, 40, 45);"
              class="elevation-1">
            <template v-slot:[`item.ticker`]="{ item, index }">
            <span :id="item.ticker">{{ index + 1 }}</span>
          </template>
            <template v-slot:[`item.address`]="{ item }">
              <span>   {{ `${item.address.substring(0, 4)}...${item.address.substring(item.address.length - 4)}`  }}</span>
            </template>
            <template v-slot:[`item.total`]="{ item  }">
              {{ parseFloat(item.total).toLocaleString('en-US', { maximumFractionDigits: 0 })  }}
            </template>
            <template v-slot:[`item.available`]="{ item  }">
              {{ parseFloat(item.available).toLocaleString('en-US', { maximumFractionDigits: 0 })  }}
            </template>
            <template v-slot:[`item.inscribed`]="{ item  }">
              {{ parseFloat(item.inscribed).toLocaleString('en-US', { maximumFractionDigits: 0 })  }}
            </template>
            <template v-slot:[`item.totalRatio`]="{ item }">
              {{ (parseFloat(item.total) / 210000).toLocaleString('en-US', { maximumFractionDigits: 1 }) }}%
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="tab-3-2" transition="none" dark eager>
          <v-data-table
              :height="tableHeight"
              :headers="lpHeaders"
              fixed-header
              hide-default-footer
              pagination="false"
              :items="lp"
              dark
              dense
              style="    scrollbar-width: auto;
    scrollbar-color: rgb(77, 77, 77) rgb(40, 40, 45);"
              class="elevation-1">
            <template v-slot:[`item.address`]="{ item }">
              <span>   {{ `${item.address.substring(0, 4)}...${item.address.substring(item.address.length - 4)}`  }}</span>
            </template>
            <template v-slot:[`item.txn`]="{ item }">
      <span >

              <v-icon :id="item.txn" color="#848489" small>mdi-open-in-new</v-icon>

      </span>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>



    </v-main>

    <v-navigation-drawer
        app
        width="400"
        clipped
        right
        color="#111116"
        style="scrollbar-width: auto;
    scrollbar-color: rgb(77, 77, 77) rgb(40, 40, 45);"
        dark
    >

      <div style="padding:8px;padding-bottom:0;color: white;font-weight: bold; display: flex;align-items: center;justify-content: center;width: 100%">
        DOGE /LAIKA
      </div>
      <div style="display: flex;align-items: center;justify-content: center;padding-top: 6px">
        <v-btn-toggle
            v-model="icon"
            borderless
        >
          <v-btn value="left"  x-small>
            <span class="hidden-sm-and-down">Website</span>

            <v-icon right>
              mdi-web
            </v-icon>
          </v-btn>

          <v-btn value="center" x-small>
            <span class="hidden-sm-and-down">Twitter</span>

            <v-icon right>
              mdi-twitter
            </v-icon>
          </v-btn>

          <v-btn value="right" style="margin-right:-6px " x-small>
            <span class="hidden-sm-and-down">Telegram</span>
            <div style="color: #FFFFFF;fill:#FFFFFF;height: 16px;margin-left: 6px;margin-right: -6px">
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:serif="http://www.serif.com/" width="14px" height="14px" version="1.1" xml:space="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1.41421;"><path id="telegram-1" d="M18.384,22.779c0.322,0.228 0.737,0.285 1.107,0.145c0.37,-0.141 0.642,-0.457 0.724,-0.84c0.869,-4.084 2.977,-14.421 3.768,-18.136c0.06,-0.28 -0.04,-0.571 -0.26,-0.758c-0.22,-0.187 -0.525,-0.241 -0.797,-0.14c-4.193,1.552 -17.106,6.397 -22.384,8.35c-0.335,0.124 -0.553,0.446 -0.542,0.799c0.012,0.354 0.25,0.661 0.593,0.764c2.367,0.708 5.474,1.693 5.474,1.693c0,0 1.452,4.385 2.209,6.615c0.095,0.28 0.314,0.5 0.603,0.576c0.288,0.075 0.596,-0.004 0.811,-0.207c1.216,-1.148 3.096,-2.923 3.096,-2.923c0,0 3.572,2.619 5.598,4.062Zm-11.01,-8.677l1.679,5.538l0.373,-3.507c0,0 6.487,-5.851 10.185,-9.186c0.108,-0.098 0.123,-0.262 0.033,-0.377c-0.089,-0.115 -0.253,-0.142 -0.376,-0.064c-4.286,2.737 -11.894,7.596 -11.894,7.596Z"/></svg>
            </div>
          </v-btn>
          <v-btn value="right"  x-small>
            <v-icon  small>
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </v-btn-toggle>
      </div>
      <v-container style="gap: 0">
        <v-row
            class="mb-0"
            dense
            style="margin-bottom: -6px!important;"
        >
          <v-col
          >
            <v-card
                class="pa-2"
                outlined
                tile
            >
              <div class="libelleGridStats">
                PRICE USD
              </div>
              <div class="gridValue">
                $0.20
              </div>
            </v-card>
          </v-col>
          <v-col
          >
            <v-card
                class="pa-2"
                outlined
                tile
            >
              <div class="libelleGridStats">
                PRICE
              </div>
              <div class="gridValue">
                1.12 DOGE
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row
            dense

        >
          <v-col
          >
            <v-card
                class="pa-2"
                outlined
                tile
            >
              <div class="libelleGridStats">
                LIQUIDITY
              </div>
              <div class="gridValue">
                $20K
              </div>
            </v-card>
          </v-col>
          <v-col
          >
            <v-card
                class="pa-2"
                outlined
                tile
            >
              <div class="libelleGridStats">
                TOTAL SUPPLY
              </div>
              <div class="gridValue">
                10M
              </div>
            </v-card>
          </v-col>
          <v-col
          >
            <v-card
                class="pa-2"
                outlined
                tile
            >
              <div class="libelleGridStats">
                MARKET CAP
              </div>
              <div class="gridValue">
                $3.5M
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <div style="display: flex;width: 100%;justify-content: flex-end;padding-bottom: 8px">
          <v-btn
              icon
            rounded
            @click="dialogSplipage=true"
              x-small
          >
            <v-icon>mdi-tune-variant</v-icon>
          </v-btn>
        </div>
      <v-card style="padding: 16px;border-color: rgb(65, 184, 131);" outlined rounded >
        <div style="display: flex;justify-content: space-between;align-items: baseline"><div>You're paying</div><div style="display: flex;align-items: center;gap: 4px">      <v-icon color="#555" x-small>mdi-wallet</v-icon>
          <div style="color: #555;font-size: 12px">82.30 DOGE</div>
          <v-chip-group
              style="padding-left: 6px"

          >
            <v-chip
                x-small
            >
              HALF
            </v-chip>
            <v-chip x-small
            >
              MAX
            </v-chip>

          </v-chip-group>
        </div></div>
        <div></div>
        <v-text-field
            placeholder="0.00"
            height="78"
            v-model="numberValue1"
            @keypress="onlyNumbers"
            input-class="align-right"
            filled
            hide-details

            rounded
        >
          <v-chip
              pill
              style="margin-top: -5px"
              slot="prepend-inner"
              large

          >
            <img style="width: 2.5em;height: 2.5em;padding-right: 8px" src="https://doggy.market/assets/doge-C39RGl_C.svg" class="doge-value">

            DOGE

          </v-chip>

        </v-text-field>
        <div style="display: flex;justify-content: center;align-items: center;margin-top: 12px">
          <v-divider/>
          <v-icon>mdi-swap-vertical</v-icon>
          <v-divider/>
        </div>

        <div style="display: flex;justify-content: space-between;align-items: center"><div>To receive</div><div style="display: flex;align-items: center;gap: 4px">      <v-icon color="#555" x-small>mdi-wallet</v-icon>
          <div style="color: #555;font-size: 12px;padding-right: 8px">0 LAIKA</div>
        </div></div>
        <div></div>
        <v-text-field
            placeholder="0.00"
            height="78"
            v-model="numberValue2"
            @keypress="onlyNumbers"
            input-class="align-right"
            filled

            rounded

        >
          <v-chip
              pill
              style="margin-top: -5px;cursor: pointer;"
              slot="prepend-inner"
              large
              @click="dialogCurrency=true"

          >
            <img style="width: 2.5em;height: 32px;padding-right: 8px" src="https://testnet.laikachain.dog/assets/network_logo_dark.png" class="doge-value">

            LAIKA
            <v-icon right>
              mdi-chevron-down
            </v-icon>
          </v-chip>



        </v-text-field>

        <v-btn @click="swapTokens" style="color:  rgb(65, 184, 131);" x-large block>{{wallet==null ? "CONNECT WALLET" : "SWAP"}}</v-btn>
      </v-card>

      </v-container>
      <div style="display: flex;flex-direction: column;gap: 7px">
      <div style="display: flex;justify-content: space-between;align-items: baseline;padding: 0 16px ">
        <div style="color: #555;font-size: 14px">1 LAIKA  ≈ 1.123204 DOGE</div>
        <div style="color: #555;font-size: 14px"></div>
      </div>
      <div style="display: flex;justify-content: space-between;align-items: baseline;padding: 0 16px ">
        <div style="color: #555;font-size: 14px">Price Impact</div>
        <div style="color: #555;font-size: 14px">0%</div>
      </div>
        <div style="display: flex;justify-content: space-between;align-items: baseline;padding: 0 16px ">
          <div style="color: #555;font-size: 14px">Minimum receive</div>
          <div style="color: #555;font-size: 14px">0</div>
        </div>
      </div>
      <v-container style="gap: 0">
        <v-row

            dense

        >
          <v-col
              style="padding: 0"
          >
            <v-card
                class="pa-2"
                outlined

                style="padding: 0;border-right: none;padding-left: 4px"
                tile
            >
              <div class="libelleGridStats">
                1H
              </div>
            </v-card>
          </v-col>
          <v-col
              style="padding: 0"
          >
            <v-card
                style="padding: 0;border-right: none;"
                class="pa-2"
                outlined
                tile
            >
              <div class="libelleGridStats">
                4H
              </div>
            </v-card>
          </v-col>
          <v-col
              style="padding: 0"
          >
            <v-card
                class="pa-2"
                outlined
                tile
                style="padding: 0;border-right: none;"

            >
              <div class="libelleGridStats">
                6H
              </div>
            </v-card>
          </v-col>
          <v-col
              style="padding: 0;padding-right: 4px"
          >
            <v-card
                class="pa-2"
                outlined
                tile
                style="padding: 0"
            >
              <div class="libelleGridStats">
                24H
              </div>

            </v-card>
          </v-col>
        </v-row>
        <v-row no-gutters style="margin:-4px;margin-top: 0;margin-right: 0">
          <v-col
              cols="6"
              md="4"
          >
            <v-card
                class="pa-2"
                outlined
                tile
                style="border-right: none"
            >
              <div style="height: 150px;margin-top:16px;margin-bottom:16px;border-right: thin solid rgba(255, 255, 255, 0.12)"></div>
            </v-card>
          </v-col>
          <v-col

              cols="12"
              sm="6"
              md="8"
          >
            <v-card
                class="pa-2"
                outlined
                tile
                style="border-left: none;height: 200px"
            >
              <div>
                <div style="display: flex;justify-content: space-between">
                  <div>
                    <div>
                      Buy
                    </div>
                    <div>
                      32
                    </div>
                  </div>
                  <div>
                    <div>
                      Buy
                    </div>
                    <div>
                      32
                    </div>
                  </div>
                </div>
              <v-progress-linear
                  background-color="pink lighten-3"
                  color="pink lighten-1"
                  value="15"
                  height="4"
              ></v-progress-linear>
              </div>
              <div>
                <div style="display: flex;justify-content: space-between">
                  <div>
                    <div>
                        Buy
                    </div>
                    <div>
                          32
                    </div>
                  </div>
                  <div>
                    <div>
                      Buy
                    </div>
                    <div>
                      32
                    </div>
                  </div>
                </div>
                <v-progress-linear
                    background-color="pink lighten-3"
                    color="pink lighten-1"
                    value="15"
                    height="4"
                ></v-progress-linear>
              </div>
              <div>
                <div style="display: flex;justify-content: space-between">
                  <div>
                    <div>
                      Buy
                    </div>
                    <div>
                      32
                    </div>
                  </div>
                  <div>
                    <div>
                      Buy
                    </div>
                    <div>
                      32
                    </div>
                  </div>
                </div>
                <v-progress-linear
                    background-color="pink lighten-3"
                    color="pink lighten-1"
                    value="15"
                    height="4"
                ></v-progress-linear>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container style="height: 100px;color: white;">
        <v-row justify="space-between" style="margin-left: 4px;margin-right: 4px;padding-top:6px;border-bottom: thin solid rgba(255, 255, 255, 0.12)"><div>Pooled DOGE</div><div>0</div></v-row>
        <v-row justify="space-between" style="margin-left: 4px;margin-right: 4px;padding-top:6px;border-bottom: thin solid rgba(255, 255, 255, 0.12)"><div>Pooled LAIKA</div><div>0</div></v-row>
        <v-row justify="space-between" style="margin-left: 4px;margin-right: 4px;padding-top:6px;border-bottom: thin solid rgba(255, 255, 255, 0.12)"><div>Pair wallet</div><div>0</div></v-row>
      </v-container>
    </v-navigation-drawer>
    <v-navigation-drawer
        v-model="drawer"
        app
        absolute
        color="transparent"
        background-color="white"
        style="z-index: 100000;box-shadow: none"
        width="500"
        temporary
        right
        dark
    >
    <div class="drawer-content">
      <div class="top">
        <div style="display: flex;align-items: center;justify-content: space-between;width: 100%">
          <div style="display: flex;align-items: center">
        <img data-v-428927fa="" alt="Logo" src="https://drc-20.org/logo.svg" width="32" height="32"  style="height: 32px; margin-right: 6px;">
        <div><div data-v-428927fa="" style="font-size: 12px;color: white"> D9J8...b2nF </div>
          <div style="display: flex;align-items: center;gap: 8px">

            <div style="font-size: 15px;color: white; font-weight: bold">

              82.30 DOGE
            </div>

          </div>
        </div>
            <v-tooltip  bottom >
              <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="ma-2"
                height="24"
                width="24"
                outlined
                fab
                v-bind="attrs"
                v-on="on"
            >
              <v-icon x-small>mdi-content-copy</v-icon>
            </v-btn>
              </template>
              <span>Copy address</span>
            </v-tooltip>
            <v-tooltip  bottom >
              <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="ma-2"
                height="24"
                width="24"
                style="margin-left: 0!important;"
                outlined
                fab
                v-bind="attrs"
                v-on="on"

            >
              <v-icon x-small>mdi-open-in-new</v-icon>
            </v-btn>
              </template>
              <span>Open in block explorer</span>
            </v-tooltip>
          </div>
          <v-tooltip  bottom min-width="150"  >
            <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="ma-2"
              x-small
              outlined
              fab
              @click="this.wallet = null"
              color="#F56565"
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-logout</v-icon>
          </v-btn>
            </template>
            <span>Disconnect wallet</span>
          </v-tooltip>
        </div>
        <h1 style="color: white;margin-top: 16px">$2 220.28</h1>
        <p style="color: whitesmoke">~10 231 DOGE</p>
      </div>
      <div class="bottom">
        <v-tabs
        centered
        v-model="tab"
        background-color="#1E1E1E"
        style="margin-bottom: 16px"
        fixed-tabs
        >
          <v-tab href="#tab-1">Tokens</v-tab>
          <v-tab href="#tab-2">Swap history</v-tab>
          <v-tab href="#tab-3">Activity</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" dark>
          <v-tab-item value="tab-1" dark>
        <v-data-table
            dark
            hide-default-header
            hide-default-footer
            :headers="tokensHeaders"
            :items="tokens"
            item-key="ticker"
        >
          <template v-slot:[`item.ticker`]="{ item }">

                <img
                    style="object-fit: cover;border-radius: 50%;vertical-align: middle;margin-right: 8px"
                    height="32"
                    width="32"

                    :src="'https://doggy.market/drc-20/'+item.ticker+'.jpg'"
                    alt="John"
                >
             {{ item.ticker }}
          </template>
          <template v-slot:[`item.overallBalance`]="{ item }">
            {{ parseFloat(item.overallBalance).toLocaleString() }}
          </template>
        </v-data-table>
          </v-tab-item >

            <v-tab-item value="tab-2">
              test
            </v-tab-item>
          <v-tab-item value="tab-3">
            test
          </v-tab-item>
        </v-tabs-items>

      </div>

    </div>



    </v-navigation-drawer>
    <v-dialog
        v-model="dialog"
        width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            v-if="wallet == null"
            class="ma-2"
            :loading="loading"
            :disabled="loading"
            color="secondary"
            v-bind="attrs"
            v-on="on"
            rounded
        >
          Connect wallet
        </v-btn>
      </template>

      <v-card  dark  style="height: 300px;z-index: 100000">
        <v-card-title>Connect Wallet</v-card-title>
        <v-card-subtitle>You need to connect a DRC-20 wallet.</v-card-subtitle>
        <v-divider></v-divider>

        <v-container>
          <div   @click=" walletChoice()" style="display: flex;align-items: center;padding: 8px;cursor: pointer;border: 1px solid #7f7f7f99;border-radius: 16px">
            <v-avatar
                size="52"

            >
              <img
                  src="https://upload.wikimedia.org/wikipedia/commons/3/36/MetaMask_Fox.svg"
                  alt="dogelabs"
              >
            </v-avatar>
            <div style="font-size: 14px;padding-left: 12px;font-weight: 600">Metamask</div>
          </div>
          <div   @click=" walletChoice()" style="margin-top:16px;display: flex;align-items: center;padding: 8px;cursor: pointer;border: 1px solid #7f7f7f99;border-radius: 16px">
            <v-avatar
                size="52"

            >
              <img
                  src="https://doggy.market/drc-20/wiww.jpg"
                  alt="dogelabs"
              >
            </v-avatar>
            <div style="font-size: 14px;padding-left: 12px;font-weight: 600">WIWW Wallet</div>
          </div>

        </v-container>

      </v-card>
    </v-dialog>
    <v-dialog
        v-model="dialogCurrency"
        scrollable
        width="500"

    >
      <v-card  dark  style="height: 340px;z-index: 100000">
        <v-autocomplete
            v-model="autocomplete"
            :items="tokenList"
            :loading="isLoading"
            :search-input.sync="search"
            chips
            clearable
            hide-details
            hide-selected
            item-text="tick"
            item-value="inscriptionId"
            label="Search for a coin..."
            solo
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                Search for your favorite
                <strong>Cryptocurrency</strong>
              </v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:selection="{ attr, on, item, selected }">
            <v-chip
                v-bind="attr"
                :input-value="selected"
                color="blue-grey"
                class="white--text"
                v-on="on"
            >
              <v-icon left>
                mdi-bitcoin
              </v-icon>
              <span v-text="item.name"></span>
            </v-chip>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-avatar
                color="indigo"
                class="text-h5 font-weight-light white--text"
            >
              {{ item.name.charAt(0) }}
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
              <v-list-item-subtitle v-text="item.symbol"></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-bitcoin</v-icon>
            </v-list-item-action>
          </template>
        </v-autocomplete>
        <v-card-text style="height: 350px;padding-top: 10px">
          <v-row
              v-for="item in tokenList"
              :key="item.ticker"
              style="display: flex;width: 100%;height: 64px"
          >
            <div   @click=" walletChoice()" style=";width:100%;display: flex;align-items: center;cursor: pointer;border-bottom: 1px solid #7f7f7f99;">
              <v-avatar
                  size="32"
              >
                <img
                    :src="item.pic"
                    alt="dogelabs"
                >
              </v-avatar>
              <div style="font-size: 14px;padding-left: 12px;font-weight: 600">{{item.tick}}</div>
              <v-btn  outlined style="margin-left: auto">{{item.tick == "dogi" ? "SWAP": "ADD LIQUIDITY"}}</v-btn>

            </div>


          </v-row>
        </v-card-text>

      </v-card>
    </v-dialog>
    <v-dialog
        v-model="dialogConfirm"
        persistent
        width="300"
    >
      <v-card
          dark
      >
        <v-card-text>
          Confirm in wallet
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="dialogSplipage"
        width="400"
    >
      <v-card  dark  style="height: 300px;z-index: 100000">
        <v-card-title>Slippage Settings</v-card-title>
        <v-divider></v-divider>

        <v-container style="padding:32px">
          <v-slider
              v-model="slippage"
              :thumb-size="32"
              max="10"
              tick-size="'0.5'"
              thumb-color="green"
              thumb-label="always"
          >
            <template v-slot:thumb-label="{ value }">
              {{ value/2 }}%
            </template>
          </v-slider>
        </v-container>
      </v-card>
    </v-dialog>
    <v-snackbar
        v-model="this.snackbar"
        color="success"
        width="100%"
        outlined
        dark
    >
      <div style="display: flex;justify-content: space-between">
        <div>
      Wallet connected.
        </div>
      <v-icon dark color="success">mdi-check</v-icon>
      </div>
    </v-snackbar>

  </v-app>
</template>
<style>
.chartSelector{
  width: 28px;
  text-align: center;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color:#d1d4dc;
  height: 32px;
}
.chartSelector:hover{
  background: #2a2e39;
}
input[input-class] {
  text-align: right;
}
.libelleGridStats{
  font-size: 12px;
  color: #797979;
  text-align: center;
  font-weight: 400;
}
.gridValue{
  font-size: 16px;
  font-weight: 800;
  text-align: center;

}
.drawer-content{
  height: calc( 100vh - 48px );
  ;
  margin-top: 48px;
}
.drawer-content > .top{
  height:  calc( 30vh - 48px );
  padding: 16px;
  background: rgb(66, 66, 66);
}
.drawer-content > .bottom{
  height: 70vh;

  background: #1E1E1E

}
.tabs-active {
  background: #1E1E1E;
  color:#41B883!important;
}
.sub-tabs-active {
  color:#41B883!important;
}
.v-navigation-drawer__border{
  display: none;
}
.slider-wrapper{

  user-select: none;
  width: 100%;
  height: 14px;
  top: -7px;
  left: 0px;

  cursor: row-resize;
  z-index: 1000;
  position: absolute;
}
.slider{
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 0px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}

</style>
<script>

import ConnectWallet from "@/components/ConnectWallet.vue";

import TradingVue from 'trading-vue-js'
import {DataCube} from "trading-vue-js/src";
import axios from "axios";
import Web3 from 'web3';
import XP from 'tvjs-xp'
import tokenLst from "./assets/token.json"
//import { Token } from '@uniswap/sdk-core'
//import { computePoolAddress } from '@uniswap/v3-sdk'


//import {ethers} from "ethers";
export default {
  name: 'App',

  components: {
    ConnectWallet,
    TradingVue
  },
  methods :{
    handleClick(toggle,chart,currency) {
      if(currency !== null){
        this.currentCurrency = currency
      }
      if (chart!==null){
        this.currentChart=chart
      }

     if (this.currentCurrency!=="DOGE"){
       axios.get('http://127.0.0.1:9090/router.php',{
         params:{
           r:this.currentChart
         }
       })
           .then(response => {
             const lines = response.data.split('\n');
             lines.shift();
             lines.pop();
             const ohlcvData = lines.map(line => {
               const values = line.split(',');
               return [
                 parseInt(values[0]),       // date
                 parseFloat(values[1]),     // open
                 parseFloat(values[2]),     // high
                 parseFloat(values[3]),     // low
                 parseFloat(values[4]),     // close
                 parseInt(values[5])      // volume
               ];
             });
             this.data.set('chart.data', ohlcvData);
             this.$refs.tradingVue.resetChart();
           })
     }else{
       let limit = 0
       if (this.currentChart == "1d"){
         limit = 170
       }else {
         limit = 500
       }
       let dogeprice = axios.get('https://api.binance.com/api/v3/klines', {
         params: {
           symbol: "DOGEUSDT",
           interval: this.currentChart,
           limit: limit,
           endTime: Date.now()
         }
       })
       axios.get('http://127.0.0.1:9090/router.php',{
         params:{
           r:this.currentChart
         }
       }).then(async (res)=>{
         let price = await dogeprice
         const lines = res.data.split('\n');
         lines.shift();
         lines.pop();
         const ohlcvData = lines.map((item,index) => {
           const values = item.split(',');

           return [price.data[index][0],
           parseFloat(values[1])/parseFloat(price.data[index][1]),   // Open price
           parseFloat(values[2])/parseFloat(price.data[index][4]),   // High price
           parseFloat(values[3])/parseFloat(price.data[index][4]),    // Low price
           parseFloat(values[4])/parseFloat(price.data[index+1][1]),  // Close price
           parseFloat(values[5])/parseFloat(price.data[index][1])  // Volume
         ]})


         this.data.set('chart.data', ohlcvData);
         this.$refs.tradingVue.resetChart();
       })
     }

      toggle();
    },
    onlyNumbers(event) {
      const charCode = event.which ? event.which : event.keyCode;
      if (
          (charCode >= 48 && charCode <= 57) || // Numbers
          charCode === 8 || // Backspace
          charCode === 46 // Decimal point
      ) {
        const dogeReserve = 100000; // Reserve amount of DOGE tokens
        const dogiReserve = 5000;   // Reserve amount of LAIKA tokens
        const inputAmount = this.numberValue1;
        const inputAmountWithFee = inputAmount * 997; // Assuming 0.3% fee (Uniswap's fee)
        const numerator = inputAmountWithFee * dogiReserve;
        const denominator = (dogeReserve * 1000) + inputAmountWithFee;

        const outputAmount =numerator / denominator;

        this.numberValue2 = outputAmount

        const newDogeReserve = dogeReserve + inputAmount;
        const newDogiReserve = dogiReserve - outputAmount;
        const initialPrice = dogiReserve / dogeReserve;

// Calculate the new price
        const newPrice = newDogiReserve / newDogeReserve;
        const priceImpact = ((newPrice - initialPrice) / initialPrice) * 100;
        const minimumReceive = outputAmount * 0.95; // Assuming a 5% tolerance
        console.log(priceImpact)
        console.log(minimumReceive)
        return true;
      } else {
        event.preventDefault();
        return false;
      }
    },
    formatTimestamp(timestamp) {
      const secondsAgo = Math.floor((Date.now() / 1000) - timestamp);

      if (secondsAgo < 60) {
        return `${secondsAgo}s ago`;
      } else if (secondsAgo < 3600) {
        const minutes = Math.floor(secondsAgo / 60);
        const remainingSeconds = secondsAgo % 60;
        return `${minutes}m ${remainingSeconds}s ago`;
      } else if (secondsAgo < 86400) {
        const hours = Math.floor(secondsAgo / 3600);
        const remainingMinutes = Math.floor((secondsAgo % 3600) / 60);
        return `${hours}h ${remainingMinutes}m ago`;
      } else if (secondsAgo < 604800) {
        const days = Math.floor(secondsAgo / 86400);
        const remainingHours = Math.floor((secondsAgo % 86400) / 3600);
        return `${days}d ${remainingHours}h ago`;
      } else if (secondsAgo < 31536000) {
        const weeks = Math.floor(secondsAgo / 604800);
        const remainingDays = Math.floor((secondsAgo % 604800) / 86400);
        return `${weeks}w ${remainingDays}d ago`;
      } else {
        const years = Math.floor(secondsAgo / 31536000);
        const remainingMonths = Math.floor((secondsAgo % 31536000) / 2628000);
        return `${years}y ${remainingMonths}m ago`;
      }
    },
    openDrawer() {
      this.drawer = true;
    },
    async swapTokens() {
 /*     const CurrentConfig = {
        rpc: {
          local: 'http://localhost:8545',
          mainnet: 'https://mainnet.infura.io/v3/0ac57a06f2994538829c14745750d721',
        },
        tokens: {
          in: USDC_TOKEN,
          amountIn: 1000,
          out: WETH_TOKEN,
          poolFee: FeeAmount.MEDIUM,
        },
      }

   //   let contractAddress = "0x1273D27d94204149EE0DD1AFD17255789B25FC13"
      const currentPoolAddress = computePoolAddress({
        factoryAddress: POOL_FACTORY_CONTRACT_ADDRESS,
        tokenA: CurrentConfig.tokens.in,
        tokenB: CurrentConfig.tokens.out,
        fee: CurrentConfig.tokens.poolFee,
      })
      const provider = new ethers.providers.JsonRpcProvider(rpcUrl)
      const poolContract = new ethers.Contract(
          currentPoolAddress,
          IUniswapV3PoolABI.abi,
          provider
      )*/
    },
    async walletChoice(){
      this.dialog = false
      this.dialogConfirm=true
      // Check if MetaMask is installed
      if (typeof window.ethereum !== 'undefined') {
        console.log('MetaMask is installed!');

        try {
          // Request account access
          await window.ethereum.request({ method: 'eth_requestAccounts' });

          // Create a new instance of web3 using MetaMask as provider
          this.web3 = new Web3(window.ethereum);



          // Get the user's address
          const accounts = await this.web3.eth.getAccounts();
          this.walletConnected()
          this.dialogConfirm=false
          this.wallet = accounts[0];

          console.log('Connected account:', this.wallet);
        } catch (error) {
          console.error('User rejected the connection:', error);
        }
      } else {
        alert('Please install MetaMask!');
      }


    },
    async connectWallet() {
      this.dialog=true

  },
    walletConnected(){
      this.snackbar=true
    },
    async swap(){
      if (this.wallet){
        await window.dogeLabs.sendBitcoin("",1,1)
      }else{
        await this.connectWallet()
      }
    },
    updateRelativeTimes() {
      this.txns = this.txns.map(txn => ({
        ...txn,
        relativeTime: this.formatTimestamp( txn.date), // Met à jour le temps relatif
      }));
    },
    handleSliderMouseDown(event) {

      const initialY = event.clientY || event.touches[0].clientY;
      const initialHeight = this.height;

      const handleMouseMove = (event) => {
        const currentY = event.clientY || event.touches[0].clientY;
        const deltaY = currentY - initialY;

        // Calculate new height
        const newHeight = initialHeight + deltaY;

        // Set minimum height
        const minHeight = 200;
        this.height = Math.max(minHeight, newHeight);
      };

      const handleMouseUp = () => {
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);
        window.removeEventListener("touchmove", handleMouseMove);
        window.removeEventListener("touchend", handleMouseUp);
      };

      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
      window.addEventListener("touchmove", handleMouseMove);
      window.addEventListener("touchend", handleMouseUp);
    }
  },
  created() {
    this.updateRelativeTimes(); // Appel initial
    this.intervalId = setInterval(this.updateRelativeTimes, 1000); // Appel périodique toutes les secondes
  },
  destroyed() {
    clearInterval(this.intervalId); // Nettoyage à la destruction du composant
  },
  computed: {
    tableHeight() {
      const appBarHeight = 132; // Adjust this value based on the height of your app bar
      return window.innerHeight - appBarHeight - this.height + 'px';
    },
  },

  mounted() {

    let self = this;
    const symbol = 'DOGEUSDT'; // Symbol for Dogecoin against USDT
    const interval = '1h'; // 1-hour interval
    const limit = 1000; // Limit the number of results to 1000 (maximum allowed)

// Make the API request
    axios.get('https://api.binance.com/api/v3/klines', {
      params: {
        symbol: symbol,
        interval: interval,
        limit: limit
      }
    })
        .then(response => {
          // Process the response data
          const ohlcvData = response.data.map(item => ([item[0],      // Open time
             parseFloat(item[1]),   // Open price
             parseFloat(item[2]),   // High price
            parseFloat(item[3]),    // Low price
            parseFloat(item[4]),  // Close price
         parseFloat(item[5])  // Volume
          ]));
          this.data.set("chart.data",ohlcvData)
            this.$refs.tradingVue.resetChart()
        })
        .catch(error => {
          console.error('Error fetching OHLCV data:', error);
        });


    axios.get("\n" +
        "https://wallet-api.dogeord.io/brc20/tokens?address=D9J83hbeW5JCHwa3zd8iW8YPexuno5b2nF&cursor=0&size=25").then((data)=>{
          self.tokens = data.data.result.list;
          console.log(self.tokens)
    })
    self.holders = [
      {
        "address": "DU8vFnq97hFhD6RG9kgaeu9avVbBgNxkFa",
        "tick": "dogi",
        "available": 0,
        "inscribed": 1000000,
        "total": 1000000
      },
      {
        "address": "DAQ7Vo8F1nAPfLdWJDMQMs4WqDhDqCqJzg",
        "tick": "dogi",
        "available": 588290.0786626947,
        "inscribed": 212713.42361416,
        "total": 801003.5022768547
      },
      {
        "address": "DCmbod9EYBa9tzRcLvSUQdKPo7xDX3APKX",
        "tick": "dogi",
        "available": 0,
        "inscribed": 500000,
        "total": 500000
      },
      {
        "address": "DGJLGH3T2NquU3YV2icQ2ECSzKzPWqU2pU",
        "tick": "dogi",
        "available": 487961,
        "inscribed": 0,
        "total": 487961
      },
      {
        "address": "DAFnMCjcovCf2jarvLvtEhzkygGHJxR2jD",
        "tick": "dogi",
        "available": 0,
        "inscribed": 487000,
        "total": 487000
      },
      {
        "address": "DDzNRf3LtgzFspaYzCSJkikat6HCsGehCa",
        "tick": "dogi",
        "available": 0,
        "inscribed": 462828,
        "total": 462828
      },
      {
        "address": "DEonAmkjg9hHhCEJtY58mTA57Bejv4Pumy",
        "tick": "dogi",
        "available": 12300,
        "inscribed": 381700,
        "total": 394000
      },
      {
        "address": "DKJ49LMj6roJUkydvys1uZv38ewznpEgpq",
        "tick": "dogi",
        "available": 0,
        "inscribed": 386345,
        "total": 386345
      },
      {
        "address": "DAjscgmNcPHz7YZ8DKSQha9agJE52hU9KM",
        "tick": "dogi",
        "available": 0,
        "inscribed": 347800,
        "total": 347800
      },
      {
        "address": "DKvS7kk7nTVNjfdyHX75oh9GuxnAFWKVAP",
        "tick": "dogi",
        "available": 0,
        "inscribed": 300000,
        "total": 300000
      },
      {
        "address": "D7q8YDWti4TA8ua1qoqEU6rin9bK2q26y3",
        "tick": "dogi",
        "available": 0,
        "inscribed": 250000,
        "total": 250000
      },
      {
        "address": "DU7wrAfvJ2yEXsKENEoXpcvw9vNgEbZ8mz",
        "tick": "dogi",
        "available": 1.55558435834,
        "inscribed": 242898.44441564166,
        "total": 242900
      },
      {
        "address": "DH9tr7iWnKXyEWuxh18Ngn1v7Gz4irbTZP",
        "tick": "dogi",
        "available": 1.55558435834,
        "inscribed": 194998.44441564166,
        "total": 195000
      },
      {
        "address": "DRbvz47sv4DAgR7tyTFMhhn9jtz3fxz4UV",
        "tick": "dogi",
        "available": 1.55558435834,
        "inscribed": 192998.44441564166,
        "total": 193000
      },
      {
        "address": "DKFX5aq7NxAS6mjmV7r4mqfS9j3w2bRgUV",
        "tick": "dogi",
        "available": 1.55558435834,
        "inscribed": 174998.44441564166,
        "total": 175000
      },
      {
        "address": "D8YJdfXhFw7wEse8MZ2bQqKBnjdqdtRywU",
        "tick": "dogi",
        "available": 167700,
        "inscribed": 0,
        "total": 167700
      },
      {
        "address": "DGyd2cxzef6rEgmRC8uaMgArvoyNv1G4qr",
        "tick": "dogi",
        "available": 166000,
        "inscribed": 0,
        "total": 166000
      },
      {
        "address": "DNSpMvika4phM6zkTvva1ggP7cqJ3FxzDT",
        "tick": "dogi",
        "available": 1.90922750864,
        "inscribed": 153733.75677249135,
        "total": 153735.666
      },
      {
        "address": "DH7xh7FEU5keMAr7eWCSXSNtwnGwgh4ksY",
        "tick": "dogi",
        "available": 0,
        "inscribed": 151000,
        "total": 151000
      },
      {
        "address": "DSZc6gGTUxxfQ9MP8ykoYasXxUQ1ihaVK8",
        "tick": "dogi",
        "available": 150000,
        "inscribed": 0,
        "total": 150000
      }
    ]

    axios.get("  http://127.0.0.1:9090/router.php?action=ticker&ticker=dogi&p=h?offset=0&limit=20"
    )
  },

  data() {
    return {
      web3: null,
      slippage:1,
        erc20Abi: [
      {
        "constant": false,
        "inputs": [
          { "name": "_spender", "type": "address" },
          { "name": "_value", "type": "uint256" }
        ],
        "name": "approve",
        "outputs": [{ "name": "success", "type": "bool" }],
        "payable": false,
        "stateMutability": "nonpayable",
        "type": "function"
      }
    ],
      dialogCurrency: false,
      dialogConfirm: false,
      dialogSplipage:false,
      dialog: false,
      tab:null,
      currentChart:"1h",
      currentCurrency:"USD",
      drawer: false,
      data: new DataCube(),
      ext: Object.values(XP),
      tokens : [],
      width: window.innerWidth-400,
      height: window.innerHeight - 300,
      numberValue1:null,
      numberValue2:null,
      holders: [],
      holdersHeaders:[
        {
          text: '#',
          align: 'start',
          sortable: false,
          value: 'ticker',
        },
        {
          text: 'ADDRESS',
          align: 'start',
          sortable: false,
          value: 'address',
        },
        {
          text: 'TOTAL BALANCE',
          align: 'start',
          sortable: false,
          value: 'total',
        },
        {
          text: '% OF TOTAL SUPPLY',
          align: 'start',
          sortable: false,
          value: 'totalRatio',
        },
        {
          text: 'AVALAIBLE',
          align: 'start',
          sortable: false,
          value: 'available',
        },
        {
          text: 'INSCRIBED',
          align: 'start',
          sortable: false,
          value: 'inscribed',
        }
      ],
      snackbar:false,
      tokensHeaders: [
        {
          text: 'DATE',
          align: 'start',
          sortable: false,
          value: 'ticker',
        },
        {
          text: 'DATE',
          align: 'end',
          sortable: false,
          value: 'overallBalance',
        },
      ],
      lp: [
        {
          address: 'D8XxzoSG5WAH8N1gDppbDTYDtCc1gCwnRN',
          per: '100%',
          amount: "20K",
          txn: 'address',
        }
      ],
      wallet: null,
      lpHeaders: [
        {
          text: 'ADDRESS',
          align: 'start',
          sortable: false,
          value: 'address',
        },
        {
          text: '%',
          align: 'end',
          sortable: false,
          value: 'per',
        },
        {
          text: 'AMOUNT',
          align: 'end',
          sortable: false,
          value: 'amount',
        },
        {
          text: 'TXN',
          align: 'end',
          sortable: false,
          value: 'txn',
        }
      ],
      tokenList:tokenLst,
      autocomplete:null,
      search: null,
      headers: [
        {
          text: 'DATE',
          align: 'start',
          sortable: false,
          value: 'relativeTime',
        },
        { text: 'TYPE', value: 'type' },
        { text: 'USD', value: 'usd' },
        { text: 'LAIKA', value: 'token' },
        { text: 'DOGE', value: 'doge' },
        { text: 'PRICE', value: 'price' },
        { text: 'TXN', value: 'txid' },
      ],
      txns: [
        { date: 1715291697, type: 'Buy', usd: 500, token: 100, doge: 3125, price: 0.005, txid: '0x1234567890' },
        { date: 1715291023, type: 'Sell', usd: 600, token: 90, doge: 2250, price: 0.006, txid: '0x0987654321' },
        { date: 1715243023, type: 'Buy', usd: 450, token: 110, doge: 2812.5, price: 0.0045, txid: '0xabcdef1234' },
        { date: 1715245023, type: 'Sell', usd: 700, token: 80, doge: 1750, price: 0.007, txid: '0x456789abcdef' },
        { date: 1715247023, type: 'Buy', usd: 550, token: 95, doge: 3437.5, price: 0.0055, txid: '0x7890abcdef12' },
        { date: 1715249023, type: 'Sell', usd: 800, token: 70, doge: 2187.5, price: 0.008, txid: '0xcdef12345678' },
        { date: 1715251023, type: 'Buy', usd: 600, token: 85, doge: 2656.25, price: 0.006, txid: '0xabcdef09876' },
        { date: 1715253023, type: 'Sell', usd: 750, token: 75, doge: 2343.75, price: 0.0075, txid: '0x123456789abc' },
        { date: 1715255023, type: 'Buy', usd: 480, token: 105, doge: 3000, price: 0.0048, txid: '0x0987654321ab' },
        { date: 1715257023, type: 'Sell', usd: 850, token: 65, doge: 2062.5, price: 0.0085, txid: '0x456789abcde' },
  ],
    }
  }
};
</script>
<style >
html,body{
  background: grey;
  overflow: hidden;
}
</style>