<template>
<div>

      <v-btn
          v-if="wallet == null"
          class="ma-2"
          :loading="loading"
          :disabled="loading"
          color="secondary"
          @click="this.handleClick"
          rounded
      >
        Connect wallet
      </v-btn>



      <v-btn
          v-if="wallet !== null"
          rounded
          class="ma-2"
          :loading="loading"
          :disabled="loading"
          color="secondary"

          @click="openDrawer"

      >
        <div           style="height:100%;display: flex;align-items: center;justify-content: center">
        <img alt="Logo" class="ant-image-img css-17sses9" src="https://upload.wikimedia.org/wikipedia/commons/3/36/MetaMask_Fox.svg" width="24" height="24" style="height: 24px;margin-left: -6px;;margin-right: 6px">
        <div style="font-size: 12px">
        {{ `${wallet.substring(0, 4)}...${wallet.substring(wallet.length - 4)}`  }}
          <v-icon >mdi-chevron-down</v-icon>
        </div>
        </div>
      </v-btn>




</div>

</template>
<style scoped>
.menu-wallet{
  box-shadow: none;
}
</style>
<script>


export default {
  name: 'ConnectWallet',
  props: {
    openDrawer: {
      type: Function,
      required: true
    },
    wallet: null,
    connectWallet: {
      type: Function,
      required: true
    }
  },
  methods: {
    async fetchData() {
      console.log(window.dogeLabs)

    },
    disconnect(){

    },
    openPopup(){
      this.dialog = true
    },
    async handleClick() {
      await this.connectWallet()
    }
  },
  mounted() {
    // Call fetchData method when the component is mounted
    this.fetchData();
  },
  data: () => ({
    snackbar: false,
    dialogConfirm: false,
    dialog: false,
    loading: false,
    transferableList: [],
  }),
}
</script>
